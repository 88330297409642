* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* @import "../node_modules/hamburgers/dist/hamburgers.min.css";/ */
/* @import '../node_modules/leaflet/dist/leaflet.css'; */
/* @import url('../node_modules/materialize-css/dist/css/materialize.min.css'); */
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url('../node_modules/slick-carousel/slick/slick.css');
@import url('../node_modules/slick-carousel/slick/slick-theme.css');

html {
  font-size: 16px;
}

@media screen and (max-width: 780px) {
  html {
    font-size: 12px;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('./assets/podelitsya/icomoon/fonts/icomoon.eot?u54h9u');
  src: url('./assets/podelitsya/icomoon/fonts/icomoon.eot?u54h9u#iefix') format('embedded-opentype'),
    url('./assets/podelitsya/icomoon/fonts/icomoon.ttf?u54h9u') format('truetype'),
    url('./assets/podelitsya/icomoon/fonts/icomoon.woff?u54h9u') format('woff'),
    url('./assets/podelitsya/icomoon/fonts/icomoon.svg?u54h9u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-left:before {
  content: "\e908";
}

.icon-chevron-thin-right:before {
  content: "\e905";
}

.icon-envelope:before {
  content: "\e901";
}

.icon-content_copy:before {
  content: "\e907";
}

.icon-message-typing:before {
  content: "\e900";
}

.icon-instagram:before {
  content: "\e902";
}

.icon-twitter:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-whatsapp:before {
  content: "\e906";
}


.icon-socset {
  /* display: block; */
  text-decoration: none;
  padding: 13px 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 2.2rem;
  /* width: 20px; */
  /* height: 20px; */
}

.icon-socset:hover {
  color: #fff;
}

.whatsapp {
  background-color: #25D366;
}

.facebook {
  /* font-size: 1.8rem; */
  padding: 13px 20px;
  background-color: #3B5998;
}

.twitter {
  background-color: #1DA1F2;
}

.telegram {
  background-color: #0F9BE5;
}

.instagram {
  /* background: #f09433; */
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.icon-copy {
  text-decoration: none;
  color: #444444;
  font-size: 1.3rem;
  padding: 15px;
  border-radius: 50%;
}

.hover {
  background-color: #f2f2f2;
  color: #444444;
}

.icon-copy:hover {
  background-color: #f2f2f2;
  color: #444444;
}

.copy-text {
  cursor: pointer;
}


.leaflet-container {
  height: 100%;
  width: 100%;
}

.hamburger {
  position: relative;
  z-index: 10000;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  width: 120px;
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
  border: none;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-radius: 50%;
  font-family: "Inter";
  font-style: normal;
  color: #3763ff;
  font-weight: 400;
  /* line-height: 20px; */
  border: 1px solid #3763ff;
  margin-right: 10px;
  font-size: 1.25rem;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 50%;
  font-family: "Inter";
  font-style: normal;
  color: #3763ff;
  font-weight: 400;
  /* line-height: 15px; */
  border: 1px solid #3763ff;
  margin-right: 10px;
  font-size: 1.25rem;
}

.leaflet-touch .leaflet-bar a {
  position: relative;
}

.leaflet-touch .leaflet-bar a>span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-52%, -52%);
}

.leaflet-div-icon {
  background-color: transparent;
  border: none;
}

.customPopup {
  /* border: 1px solid red; */
  top: -73px;
}

.marker-cluster-custom {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #58BF56;
  color: #fff;
  padding: 20px;
  /* opacity: 0.8 !important; */
  transition: 0.2s ease;
}

.green {
  background-color: rgb(43, 131, 186);
  box-shadow: 0px 0px 0px 4px rgba(43, 131, 186, 0.4);
}

.greenWhite {
  background-color: rgb(171, 221, 164);
  box-shadow: 0px 0px 0px 4px rgba(171, 221, 164, 0.4);
}

.yellow {
  background-color: rgba(255, 204, 74, 1);
  box-shadow: 0px 0px 0px 4px rgba(255, 204, 74, 0.4);
}

.yellowWhite {
  background-color: rgb(253, 174, 97);
  box-shadow: 0px 0px 0px 4px rgba(253, 174, 97, 0.4);
}

.red {
  background-color: rgb(215, 25, 28);
  box-shadow: 0px 0px 0px 4px rgba(215, 25, 28, 0.4);
}

.grey {
  background-color: rgb(182, 182, 182);
  box-shadow: 0px 0px 0px 4px rgba(182, 182, 182, 0.4);
}

* {
  font-family: "Inter";
  font-style: normal;
}

.text-width-logo {
  width: 15rem;
}

.logo__text {
  font-weight: 500;
  font-size: 0.75rem !important;
  line-height: 14px;
  vertical-align: middle;
  text-transform: uppercase;
}

.text__all {
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 140%;
}

.title__text {
  font-weight: 500;
  font-size: clamp(1rem, 0.75rem + 1.3333333333333333vw, 1.25rem);
  line-height: 130%;
  /* or 47px */
  text-transform: uppercase;
}

@media screen and (max-width: 568px) {
  .title__text {
    text-align: center;
  }
}

.devider {
  margin-top: 12px;
  margin-bottom: 50px;
}

.second_texts .second__title__text {
  margin-top: 35px;
  font-weight: 600;
  font-size: clamp(1.125rem, 1.05rem + 0.4vw, 1.5rem);
  line-height: 130%;
}

.second__title__text {
  font-size: 0.8125rem !important;
  /* margin-bottom: 24px; */
  /* margin-left: 30px; */
  font-weight: 600;
  color: #777777;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .second_texts .second__title__text {
    margin-top: 20px;
  }

  .second_texts .third__title__text {
    margin-top: 10px;
  }
}

.third__title__text {
  margin-top: 20px;
  font-weight: 600;
  font-size: clamp(1rem, 0.95rem + 0.26666666666666666vw, 1.25rem);
  line-height: 130%;
  margin-bottom: 10px;
}

.third__title__text {
  font-size: 1rem !important;
}

.btn-icon {
  padding: 10px !important;
  height: 60px !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 140% !important;
}

.btn-icon p {
  padding: 0;
}

.btn:hover {
  background-color: #6a8aff !important;
}

.btn__custom__filters {
  background-color: #6a8aff !important;
}

.bg-primary {
  background-color: #6a8aff !important;
}

.btn-primary:hover {
  background-color: #4470f3 !important;
}

.btn-primary:focus {
  background-color: #3763ff !important;
}

.map__page {
  margin-top: 20px;
  margin-bottom: 40px;
  height: 600px;
  position: relative;
}

@media screen and (max-width: 780px) {
  .map__page {
    height: 400px;
  }
}

.border-primary {
  border-color: #6a8aff !important;
}

.svg-color-change {
  filter: contrast(1%) !important;
}

.map__page iframe {
  height: clamp(22.5rem,
      16.32142857142857rem + 24.714285714285715vw,
      44.125rem);
}

footer {
  margin-top: 69px;
}

footer .text__found {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 130%;
}

.padding__button__custom {
  padding-left: 0.75rem !important;
}

.rounded__only__top__custom {
  border-radius: 7px 7px 0 0 !important;
}

.text-center img {
  vertical-align: middle;
}

.contact__text {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 130%;
}

.list-group-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 130%;
}

.list-group-item-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 140%;
}

.charts__texts {
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
  color: #292d32;
}

.numbers_fonts_weight {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
}

.font__contacts_data {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
  margin-bottom: 10px;
  margin-right: 10px;
}

.icon__info__custom {
  border: 1px solid #a2b6ff !important;
  width: 72.75px;
  height: 42px;
}

.imhere {
  border: 1px solid rgb(43, 131, 186);
  background-color: rgb(43, 131, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translate(50%); */
  /* width: 20px; */
  /* height: 20px; */
}

.btn-primary {
  background-color: #6a8aff !important;
  border-color: #6a8aff !important;
}

#headingThree>.accordion__custom__faq::after {
  margin-left: 12px !important;
}

#acc_bg__custom>.accordion {
  --bs-accordion-btn-bg: #6a8aff !important;
}

#headingThree>.accordion__custom__faq:not(.collapsed) {
  color: #ffffff !important;
  background-color: #6a8aff !important;
}

.footer__logo__text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 120%;
  vertical-align: middle;
}

.about__us__text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Основной цвет шрифтов */

  color: #292d32;
  margin-bottom: 46px;
}

.w__button__md__custom {
  width: 54px;
  height: 24px;
}

.buttons__text {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 130%;
}

.text-bg-primary {
  background-color: #6a8aff !important;
}

.bg__abouts__us__features {
  background: #ebf0ff;
  border-radius: 6px;
}

.nav-link {
  color: #000000;
}

.nav-link:hover {
  color: #ffffff;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon_filter {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.nav-link:hover {
  border: 1px solid #6a8aff;
}

.btn_hover:hover {
  background-color: #fff !important;
  color: #292d32;
}

.blue {
  border: 1px solid rgba(106, 138, 255, 0.4) !important;
}

.about_header {
  font-size: 1.25rem !important;
}

.about_text {
  font-size: 1rem !important;
  color: #777777;
}

.header__zag {
  font-size: 1.1rem;
  margin: 0;
  /* border: 1px solid red; */
}

@media screen and (max-width: 992px) {
  .like {
    width: 100% !important;
  }
}

.accordion-button:not(.collapsed)::after {
  background-image: url("/src/assets/Filter/Framewhite.svg") !important;
}

.accordion-button::after {
  background-image: url("/src/assets/Filter/Frame.svg") !important;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #6a8aff !important;
  box-shadow: none !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 45px;
}

.border-primary__custom {
  border: 1px solid #dae2ff;
}

.text__accordion__body {
  font-size: 0.875rem;
}

.text__button__custom {
  font-weight: 600;
  font-size: 1rem;
}

.podelitsya_text {
  font-size: 0.8125rem;
  cursor: pointer;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  /*FRAMEWHITE.SVG */
  /* */
  /* */
  /* */
  /* */
  /* */
  /* */
  /* */
  /* */
  /* */
}

.profession__text__styled {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 130%;
  color: #292d32;
  margin-bottom: 30px;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  display: block;
  /* border: 1px solid red; */
  color: #ffffff;
  /* margin-top: -10px; */
  font-size: 0.625rem;
  /* margin: 40px auto; */
  /* margin-left: 40px; */
  margin-right: 35px;
  position: relative;
  top: -28px;
  left: 15px;
  /* width: 200px; */
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@media screen and (max-width: 780px) {
  .loader {
    top: -20px;
  }
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

.faqHeader {
  font-size: 1.3rem;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

.foote_logo {
  font-size: 0.8rem;
  font-weight: 300;
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}


/* .licei {
  padding-right: 128px;

}

@media screen and (max-width: 790px) {
  .licei {
    padding-right: 10px;
  }
} */

.map_loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}

.map_loader:before,
.map_loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.map_loader:after {
  color: #FF3D00;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

/* preloader - 2 */

.map2_loader {
  font-size: 0.5rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}